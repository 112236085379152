import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Details from "./Details";
import GroupDetails from "./GroupDetails";
import List from "./List";
import { classes } from "lib/helpers";
import { defaultInput } from "api/measurements";
import styles from "./Measurements.module.scss";

function Measurements() {
  // input is created here, so that it is preserved
  // when going from Details view back to to List view
  const [input, setInput] = useState(defaultInput);

  return (
    <div className={classes("dashboardSection", styles.root)}>
      <Switch>
        <Route exact path="/measurements">
          <List input={input} onInput={setInput} />
        </Route>
        <Route exact path="/measurements/:uuid">
          <Details />
        </Route>
        <Route exact path="/measurements/group/:uuid">
          <GroupDetails />
        </Route>
        <Route>
          <Redirect to="/measurements" />
        </Route>
      </Switch>
    </div>
  );
}

export default Measurements;
