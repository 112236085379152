import { Link, useHistory } from "react-router-dom";
import { changePassword, changePasswordInput } from "api/account";
import Form from "components/Form";
import Password from "components/Password";
import React from "react";
import { T } from "lib/language";
import styles from "./ChangePassword.module.scss";
import useMessage from "lib/message";

function ChangePassword() {
  const history = useHistory();
  const sendMessage = useMessage();

  return (
    <>
      <Form
        className={styles.form}
        schema={changePasswordInput}
        onSubmit={async (data) => {
          await changePassword(data);
          sendMessage(<T id="account.general.success" />);
          history.push("/account/general");
        }}
      >
        <Password required name="current_password">
          <T id="password.old" />
        </Password>
        <Password required name="new_password">
          <T id="password.new" />
        </Password>
        <Password required name="password_confirm">
          <T id="password.confirm" />
        </Password>
        <button type="submit" className="cta medium">
          <T id="submit" />
        </button>
      </Form>

      <Link to="/account/general" className="ctaLink">
        <T id="back" />
      </Link>
    </>
  );
}

export default ChangePassword;
