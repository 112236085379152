import React from "react";
import { T } from "./language";

import barleyGroupSrc from "images/species/barley_group.svg";
import barleySrc from "images/species/barley.svg";
import defaultGroupSrc from "images/species/default_group.svg";
import defaultSrc from "images/species/default.svg";
import durumGroupSrc from "images/species/durum_group.svg";
import durumSrc from "images/species/durum.svg";
import maizeGroupSrc from "images/species/maize_group.svg";
import maizeSrc from "images/species/maize.svg";
import mustardGroupSrc from "images/species/rapeseed_group.svg";
import mustardSrc from "images/species/rapeseed.svg";
import oatGroupSrc from "images/species/oat_group.svg";
import oatSrc from "images/species/oat.svg";
import rapeseedGroupSrc from "images/species/rapeseed_group.svg";
import rapeseedSrc from "images/species/rapeseed.svg";
import ryeGroupSrc from "images/species/rye_group.svg";
import ryeSrc from "images/species/rye.svg";
import soybeanGroupSrc from "images/species/soybean_group.svg";
import soybeanSrc from "images/species/soybean.svg";
import sunflowerGroupSrc from "images/species/sunflower_group.svg";
import sunflowerSrc from "images/species/sunflower.svg";
import wheatGroupSrc from "images/species/wheat_group.svg";
import wheatSrc from "images/species/wheat.svg";
export const wheat = "1fd7510c-ce33-4491-bcbe-9707da557e3c";
export const barley = "cb959930-134e-4197-a2f3-9bb596ac754b";
export const oat = "342df674-a4b5-4c50-a0bc-f900ac61e3e1";
export const rye = "fe50c850-f47a-4848-ad47-5d2cf669d2af";
export const rapeseed = "a9a90071-9d78-40eb-855e-c038f2e93a6f";
export const soybean = "7e6e59fc-b32d-4eed-b70f-ac62e494b518";
export const maize = "f40074f8-c530-440d-ad2a-ff2544fe1395";
export const durum = "fa5332da-a331-49f5-b81d-1478dc9a9391";
export const sunflower = "85d420f2-1c4c-43f0-a6fa-cfdb6b57353d";
export const mustard = "a03aaa79-5441-4f5c-b2d9-04d617ce6268";

export const calibrations = [
  wheat,
  barley,
  oat,
  rye,
  rapeseed,
  soybean,
  maize,
  durum,
  sunflower,
  mustard,
] as const;

export function calibrationHasOilCorrection(calibration: string) {
  return [rapeseed, soybean, sunflower].includes(calibration);
}
export function calibrationHasGlutenGOSTCorrection(calibration: string) {
  return calibration === wheat;
}
export function calibrationHasGlutenISOCorrection(calibration: string) {
  return calibration === wheat || calibration === durum;
}

function calibrationSrc(calibration: string) {
  if (calibration === wheat) {
    return wheatSrc;
  } else if (calibration === barley) {
    return barleySrc;
  } else if (calibration === oat) {
    return oatSrc;
  } else if (calibration === rye) {
    return ryeSrc;
  } else if (calibration === rapeseed) {
    return rapeseedSrc;
  } else if (calibration === soybean) {
    return soybeanSrc;
  } else if (calibration === maize) {
    return maizeSrc;
  } else if (calibration === durum) {
    return durumSrc;
  } else if (calibration === sunflower) {
    return sunflowerSrc;
  } else if (calibration === mustard) {
    return mustardSrc;
  } else {
    return defaultSrc;
  }
}

export function CalibrationImg({ calibration }: { calibration: string }) {
  return <img src={calibrationSrc(calibration)} alt="" />;
}

function calibrationGroupSrc(calibration: string) {
  if (calibration === wheat) {
    return wheatGroupSrc;
  } else if (calibration === barley) {
    return barleyGroupSrc;
  } else if (calibration === oat) {
    return oatGroupSrc;
  } else if (calibration === rye) {
    return ryeGroupSrc;
  } else if (calibration === rapeseed) {
    return rapeseedGroupSrc;
  } else if (calibration === soybean) {
    return soybeanGroupSrc;
  } else if (calibration === maize) {
    return maizeGroupSrc;
  } else if (calibration === durum) {
    return durumGroupSrc;
  } else if (calibration === sunflower) {
    return sunflowerGroupSrc;
  } else if (calibration === mustard) {
    return mustardGroupSrc;
  } else {
    return defaultGroupSrc;
  }
}

export function CalibrationGroupImg({ calibration }: { calibration: string }) {
  return <img src={calibrationGroupSrc(calibration)} alt="" />;
}

export function getCalibrationTextId(calibration: string) {
  if (calibration === wheat) {
    return "calibration.wheat";
  } else if (calibration === barley) {
    return "calibration.barley";
  } else if (calibration === oat) {
    return "calibration.oat";
  } else if (calibration === rye) {
    return "calibration.rye";
  } else if (calibration === rapeseed) {
    return "calibration.rapeseed";
  } else if (calibration === soybean) {
    return "calibration.soybean";
  } else if (calibration === maize) {
    return "calibration.maize";
  } else if (calibration === durum) {
    return "calibration.durum";
  } else if (calibration === sunflower) {
    return "calibration.sunflower";
  } else if (calibration === mustard) {
    return "calibration.mustard";
  } else {
    return "calibration.unknown";
  }
}

export function CalibrationText({ calibration }: { calibration: string }) {
  return <T id={getCalibrationTextId(calibration)} />;
}
