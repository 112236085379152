import { DeviceCorrection } from "api/iba";
import React from "react";
import { createContext } from "react";

interface MeasurementGroupRefreshContextType {
  setDoRefresh: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export const MeasurementGroupRefreshContext =
  createContext<MeasurementGroupRefreshContextType>({
    setDoRefresh: undefined,
  });

type calculatorContextType = {
  hasOil: boolean | undefined;
  fieldValues: { [key: string]: number };
  setFieldValues: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  formulaProtein: string | undefined;
  setFormulaProtein: React.Dispatch<React.SetStateAction<string | undefined>>;
  newIbaProtein: number | undefined;
  setNewIbaProtein: React.Dispatch<React.SetStateAction<number | undefined>>;
  formulaMoisture: string | undefined;
  setFormulaMoisture: React.Dispatch<React.SetStateAction<string | undefined>>;
  newIbaMoisture: number | undefined;
  setNewIbaMoisture: React.Dispatch<React.SetStateAction<number | undefined>>;
  formulaOil: string | undefined;
  setFormulaOil: React.Dispatch<React.SetStateAction<string | undefined>>;
  newIbaOil: number | undefined;
  setNewIbaOil: React.Dispatch<React.SetStateAction<number | undefined>>;
  rowCount: number;
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
  correction: DeviceCorrection | null | undefined;
  calibration: string;
  onClickButton: undefined | Function;
  maxRowCount: number;
  submitButtonRef: React.RefObject<HTMLButtonElement> | null;
};

export const initRowCount = 5;
export const maxRowCount = 10;

export const ibaCalculatorContext = createContext<calculatorContextType>({
  hasOil: undefined,
  fieldValues: {},
  setFieldValues: () => {},
  formulaProtein: undefined,
  setFormulaProtein: () => {},
  newIbaProtein: undefined,
  setNewIbaProtein: () => {},
  formulaMoisture: undefined,
  setFormulaMoisture: () => {},
  newIbaMoisture: undefined,
  setNewIbaMoisture: () => {},
  formulaOil: undefined,
  setFormulaOil: () => {},
  newIbaOil: undefined,
  setNewIbaOil: () => {},
  rowCount: initRowCount,
  setRowCount: () => {},
  correction: undefined,
  calibration: "",
  onClickButton: undefined,
  maxRowCount: maxRowCount,
  submitButtonRef: null,
});
