import { Measurement, MeasurementsListItem } from "api/measurements";
import React, { useMemo } from "react";
import { Map as BaseMap } from "lib/map";
import { T } from "lib/language";
import imgMapClose from "images/mapClose.svg";
import imgMapOpen from "images/mapOpen.svg";

export function MapToggle({
  open,
  onOpen,
}: {
  open: boolean;
  onOpen: (open: boolean) => void;
}) {
  return (
    <button className="ctaLink medium mapToggle" onClick={() => onOpen(!open)}>
      <img src={open ? imgMapClose : imgMapOpen} alt="toggle map" />
      {open ? (
        <T id="measurements.closeMap" />
      ) : (
        <T id="measurements.openMap" />
      )}
    </button>
  );
}

function toPosition(m: Measurement | MeasurementsListItem) {
  if (
    !m.hasOwnProperty("gps_latitude") ||
    !m.hasOwnProperty("gps_longitude") ||
    m.gps_latitude === null ||
    m.gps_longitude === null ||
    m.gps_latitude === undefined ||
    m.gps_longitude === undefined
  ) {
    return null;
  }

  return { lat: m.gps_latitude, lng: m.gps_longitude };
}

function Map({
  measurement,
  measurements,
  onClick,
  onDrag,
  onHovered,
  setResetToBounds,
  resetToBounds,
}: {
  measurement?: Measurement;
  measurements?: Measurement[] | MeasurementsListItem[];
  clickable?: boolean;
  draggable?: boolean;
  onClick?: (m: Measurement | MeasurementsListItem) => void;
  onDrag?: (
    position: google.maps.LatLng,
    m: Measurement | MeasurementsListItem
  ) => void;
  onHovered?: (item: Measurement | MeasurementsListItem | null) => void;
  setResetToBounds: React.Dispatch<React.SetStateAction<boolean>>;
  resetToBounds: boolean;
}) {
  const concatedMeasurements = useMemo(
    () =>
      measurement ? [measurement, ...(measurements || [])] : measurements || [],
    [measurement, measurements]
  );

  return (
    <BaseMap
      items={concatedMeasurements}
      getLatLng={toPosition}
      onClick={onClick}
      onDrag={onDrag}
      onHovered={onHovered}
      resetToBounds={resetToBounds}
      setResetToBounds={setResetToBounds}
    />
  );
}

export default Map;
