import React, { ReactNode } from "react";
import { T } from "lib/language";
import openNewTab from "images/openNewTab.svg";
import styles from "./Info.module.scss";

function Resource({ children, href }: { children: ReactNode; href: string }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      <span className="ctaLink">{children}</span>
      <img src={openNewTab} alt="open in new tab" />
    </a>
  );
}

function Info() {
  return (
    <div className="dashboardSection">
      <h1>
        <T id="info" />
      </h1>
      <div className={styles.resources}>
        <Resource href="https://grainsense.com/pages/support">
          <T id="info.faq" />
        </Resource>
        <Resource href="https://commercial-static.ams3.cdn.digitaloceanspaces.com/website-documents/GS_QuickGuide.pdf">
          <T id="info.quickGuide" />
        </Resource>
        <Resource href="https://grainsense.com/pages/privacy-policy">
          <T id="info.privacyPolicy" />
        </Resource>
        <Resource href="https://grainsense.com/pages/terms-of-use">
          <T id="info.termsOfUse" />
        </Resource>
      </div>
    </div>
  );
}

export default Info;
