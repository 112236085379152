import bg from "lang/bg.json";
import cs from "lang/cs.json";
import en from "lang/en.json";
import es from "lang/es.json";
import fi from "lang/fi.json";
import fr from "lang/fr.json";
import pt from "lang/pt.json";
import ro from "lang/ro.json";
import ru from "lang/ru.json";
import tr from "lang/tr.json";
import uk from "lang/uk.json";

export const messages = {
  en,
  cs,
  es,
  fi,
  fr,
  ru,
  tr,
  pt,
  uk,
  ro,
  bg,
};

export const defaultLocale = "en";
export const defaultMessages = messages[defaultLocale];

export type Locale = keyof typeof messages;
export type Messages = typeof defaultMessages;
export type Message = keyof Messages;

export const languages: Record<Locale, string> = {
  bg: "български",
  en: "English",
  es: "Español",
  fi: "suomi",
  cs: "čeština",
  fr: "français",
  ru: "русский",
  tr: "Türkçe",
  pt: "Português",
  uk: "Україна",
  ro: "română",
};

export const api = process.env.REACT_APP_API;
export const create_account_url = process.env.REACT_APP_CREATE_ACCOUNT_URL;
export const maps_api_key = process.env.REACT_APP_MAPS_API_KEY;
