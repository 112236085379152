import React, { useEffect, useRef } from "react";
import { classes } from "lib/helpers";
import styles from "./Collapse.module.scss";

function Collapse({
  open,
  className,
  children,
  ...params
}: JSX.IntrinsicElements["div"] & {
  open?: boolean;
}) {
  const collapse = useRef<HTMLDivElement>(null);
  const measurer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (collapse.current === null || measurer.current === null) {
      return;
    }
    if (open) {
      collapse.current.style.height = measurer.current.clientHeight + "px";
    } else {
      collapse.current.style.height = "0px";
    }
  }, [open]);

  return (
    <div ref={collapse} className={classes(styles.root, className)} {...params}>
      <div ref={measurer}>{children}</div>
    </div>
  );
}

export default Collapse;
