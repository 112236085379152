import React, { ReactNode, createContext, useContext, useState } from "react";
import Modal from "components/Modal";
import { T } from "./language";

const Context = createContext((message: ReactNode) => {});

export function MessageProvider({ children }: { children?: ReactNode }) {
  const [message, setMessage] = useState<ReactNode>();

  return (
    <Context.Provider value={setMessage}>
      {children}
      {message ? (
        <Modal
          title={message}
          closeContent={<T id="close" />}
          onClose={() => setMessage(null)}
        />
      ) : null}
    </Context.Provider>
  );
}

export default function useMessage() {
  return useContext(Context);
}
