import {
  CalibrationGroupImg,
  CalibrationImg,
  CalibrationText,
} from "lib/calibrations";
import {
  ComprehensivenessIndicator,
  ComprehensivenessTitle,
} from "./ComprehensivenessIndicator";
import { FormattedDate, FormattedTime } from "react-intl";
import { Measurement, MeasurementsListItem } from "api/measurements";
import { classes, formatPercentage } from "lib/helpers";
import Checkbox from "components/Checkbox";
import React from "react";
import { T } from "lib/language";
import attachmentImage from "images/attachment.svg";
import sort from "images/sort.svg";
import styles from "./Table.module.scss";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

function AttachmentImg({ attachment }: { attachment: string | null }) {
  const intl = useIntl();

  return attachment ? (
    <img
      src={attachmentImage}
      alt={String(intl.messages["measurement.attachment"])}
      title={String(intl.messages["measurement.attachment"])}
    />
  ) : null;
}

function TableHead({
  measurements,
  ordering,
  onOrdering,
  checked,
  onChecked,
  hasAttachments,
}: {
  measurements: MeasurementsListItem[];
  ordering: string;
  onOrdering: (ordering: string) => void;
  checked: MeasurementsListItem[];
  onChecked: (checked: MeasurementsListItem[]) => void;
  hasAttachments: boolean;
}) {
  function ordClass(name: string) {
    return ordering.endsWith(name)
      ? ordering.startsWith("-")
        ? styles.ordDesc
        : styles.ordAsc
      : styles.noOrd;
  }

  function toggle(name: string) {
    const newSort = ordering === "-" + name ? name : "-" + name;
    onOrdering(newSort);
  }

  return (
    <thead>
      <tr>
        <th className={styles.check}>
          <Checkbox
            checked={
              checked.length > 0 && checked.length === measurements.length
            }
            indeterminate={
              checked.length > 0 && checked.length < measurements.length
            }
            onChecked={(c) => onChecked(c ? [...measurements] : [])}
          />
        </th>
        <th className={styles.comprehensivenessColumn}>
          <ComprehensivenessTitle />
        </th>
        <th className={styles.species} onClick={() => toggle("calibration")}>
          <T id="measurement.species" />
          <img src={sort} alt="sort" className={ordClass("calibration")} />
        </th>
        <th>
          <T id="measurement.groupName" />
        </th>
        <th className={styles.comment} onClick={() => toggle("comment")}>
          <T id="measurement.comment" />
          <img src={sort} alt="sort" className={ordClass("comment")} />
        </th>
        <th className={styles.date} onClick={() => toggle("measured_at")}>
          <T id="measurement.date" />
          <img src={sort} alt="sort" className={ordClass("measured_at")} />
        </th>
        <th className={styles.compound} onClick={() => toggle("protein")}>
          <T id="measurement.protein" />
          <img src={sort} alt="sort" className={ordClass("protein")} />
        </th>
        <th className={styles.compound} onClick={() => toggle("moisture")}>
          <T id="measurement.moisture" />
          <img src={sort} alt="sort" className={ordClass("moisture")} />
        </th>
        <th className={styles.compound} onClick={() => toggle("carbohydrate")}>
          <T id="measurement.carbohydrate" />
          <img src={sort} alt="sort" className={ordClass("carbohydrate")} />
        </th>
        <th className={styles.compound} onClick={() => toggle("oil")}>
          <T id="measurement.oil" />
          <img src={sort} alt="sort" className={ordClass("oil")} />
        </th>
        <th
          className={classes(
            styles.attachmentColumn,
            !hasAttachments ? styles.hidden : null
          )}
        ></th>
      </tr>
    </thead>
  );
}

function TableBody({
  measurements,
  checked,
  hovered,
  onChecked,
  hasAttachments,
}: {
  measurements: MeasurementsListItem[];
  checked: MeasurementsListItem[];
  hovered: MeasurementsListItem | Measurement | null;
  onChecked: (checked: MeasurementsListItem[]) => void;
  hasAttachments: boolean;
}) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <tbody>
      {measurements.map((m: MeasurementsListItem) => (
        <tr
          key={m.uuid}
          className={classes(
            (hovered?.uuid === m.uuid ||
              m.measurements?.some((item) => item.uuid === hovered?.uuid)) &&
              styles.hovered
          )}
          onClick={() => {
            m.isGroup
              ? history.push(`/measurements/group/${m.uuid}`)
              : history.push(`/measurements/${m.uuid}`);
          }}
          title={
            m.device
              ? intl.messages["measurements.details.gsAnalyzerSN"] +
                ": " +
                m.device.toString()
              : undefined
          }
        >
          <td className={styles.check} onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={checked.includes(m)}
              onChecked={(c) =>
                onChecked(
                  c ? [...checked, m] : checked.filter((other) => other !== m)
                )
              }
            />
          </td>
          <td className={styles.comprehensivenessColumn}>
            <ComprehensivenessIndicator
              comprehensiveness={m.comprehensiveness}
            />
          </td>

          <td className={styles.species}>
            <div className={styles.speciesContainer}>
              {m.isGroup ? (
                <CalibrationGroupImg calibration={m.calibration} />
              ) : (
                <CalibrationImg calibration={m.calibration} />
              )}

              <div className={styles.speciesText}>
                <CalibrationText calibration={m.calibration} />
              </div>
            </div>
          </td>
          <td className={styles.groupName}>{m.groupName}</td>
          <td className={styles.comment} title={m.comment}>
            {m.comment}
          </td>
          <td>
            <div>
              <FormattedDate value={m.measured_at} />
            </div>
            <div>
              <FormattedTime timeStyle={"medium"} value={m.measured_at} />
            </div>
          </td>
          <td className={styles.compound}>{formatPercentage(m.protein)}</td>
          <td className={styles.compound}>{formatPercentage(m.moisture)}</td>
          <td className={styles.compound}>
            {formatPercentage(m.carbohydrate)}
          </td>
          <td className={styles.compound}>{formatPercentage(m.oil)}</td>
          <td className={classes(!hasAttachments ? styles.hidden : null)}>
            <AttachmentImg attachment={m.attachment} />
          </td>
        </tr>
      ))}
    </tbody>
  );
}

function Table({
  measurements,
  ordering,
  onOrdering,
  checked,
  hovered,
  onChecked,
}: {
  measurements: MeasurementsListItem[];
  ordering: string;
  onOrdering: (ordering: string) => void;
  checked: MeasurementsListItem[];
  hovered: MeasurementsListItem | Measurement | null;
  onChecked: (checked: MeasurementsListItem[]) => void;
}) {
  let hasAttachments = !!measurements.filter((m) => m.attachment !== null)
    .length;

  return (
    <div className={styles.root}>
      <table>
        <TableHead
          measurements={measurements}
          ordering={ordering}
          onOrdering={onOrdering}
          checked={checked}
          onChecked={onChecked}
          hasAttachments={hasAttachments}
        />
        {
          <TableBody
            measurements={measurements}
            checked={checked}
            hovered={hovered}
            onChecked={onChecked}
            hasAttachments={hasAttachments}
          />
        }
      </table>
    </div>
  );
}

export default Table;
