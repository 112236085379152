import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Loader } from "@googlemaps/js-api-loader";
import cluster1 from "images/cluster1.svg";
import cluster2 from "images/cluster2.svg";
import { maps_api_key } from "config";
import pin from "images/pin.svg";
const loader = new Loader({
  apiKey: maps_api_key || "",
});

const Context = createContext<{
  google?: typeof google;
  maxZoomService?: google.maps.MaxZoomService;
}>({});

export function MapsProvider({ children }: { children?: ReactNode }) {
  const [state, setState] = useState<{
    google?: typeof google;
    maxZoomService?: google.maps.MaxZoomService;
  }>({});

  useEffect(() => {
    (async () => {
      const google = await loader.load();
      const maxZoomService = new google.maps.MaxZoomService();
      setState({ google, maxZoomService });
    })();
  }, []);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

const defaultCenter = { lat: 0, lng: 0 };
const defaultZoom = 2;

export function Map<I>({
  items,
  getLatLng,
  onDrag,
  onClick,
  onHovered,
  setResetToBounds,
  resetToBounds,
}: {
  items: I[];
  getLatLng: (
    item: I
  ) => google.maps.LatLngLiteral | google.maps.LatLng | undefined | null;
  onDrag?: (position: google.maps.LatLng, item: I) => void;
  onClick?: (item: I) => void;
  onHovered?: (item: I | null) => void;
  setResetToBounds: React.Dispatch<React.SetStateAction<boolean>>;
  resetToBounds: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { google, maxZoomService } = useContext(Context);
  const [map, setMap] = useState<google.maps.Map>();
  const [cluster, setCluster] = useState<MarkerClusterer>();

  useEffect(() => {
    if (google && ref.current) {
      const map = new google.maps.Map(ref.current, {
        center: defaultCenter,
        zoom: defaultZoom,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: { position: google.maps.ControlPosition.TOP_LEFT },
      });
      const cluster = new MarkerClusterer({
        map,
        markers: [],
        renderer: {
          render({ count, position }: Cluster) {
            const isSmall = count < 10;
            return new google.maps.Marker({
              icon: {
                url: isSmall ? cluster1 : cluster2,
                scaledSize: isSmall
                  ? new google.maps.Size(24, 28)
                  : new google.maps.Size(32, 37),
              },
              label: {
                text: String(count),
                color: "#fff",
                fontSize: isSmall ? "12px" : "14px",
                fontWeight: "bold",
                fontFamily: "Karmilla,sans-serif",
              },
              position,
            });
          },
        },
      });
      setCluster(cluster);
      setMap(map);
    }
  }, [google, ref]);

  useEffect(() => {
    (async () => {
      if (!google || !map || !maxZoomService || !cluster) {
        return;
      }

      if (items.length === 0) {
        map.setCenter(defaultCenter);
        map.setZoom(defaultZoom);
        cluster.clearMarkers();
        return;
      }

      const markers = items.flatMap((item, i) => {
        const position = getLatLng(item);
        if (!position) {
          return [];
        }
        const marker = new google.maps.Marker({
          position,
          icon: pin,
          clickable: Boolean(onClick) || Boolean(onHovered),
          draggable: Boolean(onDrag),
        });
        if (onClick) {
          marker.addListener("click", () => onClick(item));
        }
        if (onDrag) {
          marker.addListener("dragend", () =>
            onDrag(marker.getPosition()!, item)
          );
        }
        if (onHovered) {
          marker.addListener("mouseover", () => onHovered(item));
          marker.addListener("mouseout", () => onHovered(null));
        }
        return [marker];
      });

      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker.getPosition()!));
      const center = bounds.getCenter();

      let maxZoom: number;
      try {
        maxZoom = (await maxZoomService.getMaxZoomAtLatLng(center)).zoom;
      } catch (e) {
        maxZoom = 5;
      }

      cluster.clearMarkers();
      cluster.addMarkers(markers);

      if (resetToBounds) {
        map.fitBounds(bounds);
        setResetToBounds(false);
      }

      const zoom = map.getZoom();
      if (zoom !== undefined && zoom > maxZoom) {
        map.setZoom(maxZoom);
      }
    })();
  }, [
    google,
    map,
    maxZoomService,
    cluster,
    items,
    getLatLng,
    onHovered,
    onClick,
    onDrag,
    resetToBounds,
    setResetToBounds,
  ]);

  return <div style={{ width: "100%", height: "100%" }} ref={ref} />;
}
