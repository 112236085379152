import React, { useMemo } from "react";
import errorImage from "images/error.svg";
import { formatErrors } from "lib/helpers";
import styles from "./OtherErrors.module.scss";
import { useForm } from "./Form";

function OtherErrors({ errors }: { errors?: string[] }) {
  const { otherErrors } = useForm();
  const combinedErrors = useMemo(
    () => (errors ? [...errors, ...otherErrors] : otherErrors),
    [errors, otherErrors]
  );
  if (combinedErrors.length === 0) {
    return null;
  }
  return (
    <div className={styles.root}>
      <img src={errorImage} alt="error" />
      {formatErrors(combinedErrors)}
    </div>
  );
}

export default OtherErrors;
