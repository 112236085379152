import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import ChangePassword from "./general/ChangePassword";
import General from "./general/General";
import React from "react";
import { T } from "lib/language";
import styles from "./Account.module.scss";

function Account() {
  return (
    <div className="dashboardSection">
      <h1>
        <T id="account" />
      </h1>
      <div className="dashboardTop navigation">
        <NavLink
          to="/account/general"
          className="ctaLink navigation"
          activeClassName="active"
        >
          <T id="account.general" />
        </NavLink>
        <Route exact path="/account/general/change-password">
          <div className={styles.changePasswordNav}>
            {"> "}
            <T id="account.general.changePassword" />
          </div>
        </Route>
      </div>
      <Switch>
        <Route exact path="/account/general">
          <General />
        </Route>
        <Route exact path="/account/general/change-password">
          <ChangePassword />
        </Route>
        <Route>
          <Redirect to="/account/general" />
        </Route>
      </Switch>
    </div>
  );
}

export default Account;
