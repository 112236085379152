import React, { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import OtherErrors from "components/OtherErrors";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import imgDevice from "images/device.svg";
import imgEmail from "images/email.svg";
import imgMobile from "images/mobile.svg";
import styles from "./Verify.module.scss";
import { useQueryParams } from "lib/helpers";
import { useVerify } from "api/account";

function Verify() {
  const query = useQueryParams();
  const params = useMemo(() => {
    const token = query.get("token") || "invalid token";
    const email = query.get("email") || "";
    return { email, token };
  }, [query]);
  const [, loading, errors] = useVerify(params);

  if (errors.length) {
    return (
      <>
        <h1>
          <T id="verify" />
        </h1>
        <OtherErrors errors={errors} />
      </>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h3>
        <T id="verify.success" />
      </h3>
      <h1>
        <T id="verify.thankYou" />
      </h1>
      <p>
        <T
          id="verify.description"
          values={{
            a: (c: ReactNode) => (
              <a
                href="https://www.grainsense.com/contactus"
                target="_blank"
                rel="noopener noreferrer"
              >
                {c}
              </a>
            ),
          }}
        />
      </p>
      <div className={styles.steps}>
        <div className={styles.step}>
          <img src={imgMobile} alt="mobile" />
          <p>
            <T id="verify.mobile" />
          </p>
        </div>
        <div className={styles.step}>
          <img src={imgDevice} alt="device" />
          <p>
            <T id="verify.device" />
          </p>
        </div>
        <div className={styles.step}>
          <img src={imgEmail} alt="email" />
          <p>
            <T id="verify.email" />
          </p>
          <p>
            <T id="verify.emailNote" />
          </p>
        </div>
      </div>
      <Link to="/login" className="ctaLink">
        <T id="goToLogin" />
      </Link>
    </>
  );
}

export default Verify;
