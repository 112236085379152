import { Locale, languages } from "config";
import React, { useCallback, useMemo, useState } from "react";
import Dropdown from "./Dropdown";
import Spinner from "components/Spinner";
import { changeLanguage } from "api/login";
import { useSession } from "lib/session";

function LanguageSelector() {
  const { locale, setLocale, token } = useSession();
  const [isLoading, setIsLoading] = useState(false);

  const locales = useMemo(
    () => Object.keys(languages).filter((k) => k !== locale) as Locale[],
    [locale]
  );

  const updateLocale = useCallback(
    (locale) => {
      setLocale(locale);
      if (token) {
        setIsLoading(true);
        changeLanguage({ language: locale }).then(() => {
          setIsLoading(false);
        });
      }
    },
    [token, setLocale]
  );

  return (
    <>
      {isLoading ? <Spinner overlay={true} /> : null}
      <Dropdown
        options={locales}
        onSelect={updateLocale}
        getKey={(locale) => locale}
        render={(locale) => languages[locale]}
      >
        {languages[locale]}
      </Dropdown>
    </>
  );
}

export default LanguageSelector;
