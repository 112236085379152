import { FormattedDate } from "react-intl";
import React from "react";
import { T } from "lib/language";
import openNewTab from "images/openNewTab.svg";
import styles from "./ReleaseNotes.module.scss";
import { useReleaseNotes } from "api/releaseNotes";

function ReleaseNotes() {
  const [releaseNotes] = useReleaseNotes();

  return (
    <div className="dashboardSection">
      <h1>
        <T id="releaseNotes" />
      </h1>
      <div className={styles.resources}>
        {releaseNotes?.calibrationReleaseNotes.map((note, i) => (
          <a
            key={i}
            target="_blank"
            rel="noopener noreferrer"
            href={note.url_tr}
          >
            <span className="ctaLink">
              {note.title_tr}
              {" - "}
              <FormattedDate value={note.date} />
            </span>
            <img src={openNewTab} alt="open in new tab" />
          </a>
        ))}
      </div>
    </div>
  );
}

export default ReleaseNotes;
