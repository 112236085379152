import React, { ReactNode, useEffect, useState } from "react";
import BasePopup from "./BasePopup";
import styles from "./Dropdown.module.scss";

type props<O> = {
  children?: React.ReactNode;
  options: O[];
  getKey: (o: O) => number | string;
  render: (o: O) => ReactNode;
  getDisabled?: (o: O) => boolean;
  onSelect?: (o: O) => void;
  disabled?: boolean;
};

function Dropdown<K>({
  children,
  options,
  getKey,
  render,
  getDisabled,
  onSelect,
  disabled,
}: props<K>) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  return (
    <BasePopup
      button={children}
      popupClass={styles.popup}
      open={open}
      onOpen={setOpen}
      disabled={disabled}
    >
      {options.map((option) => {
        return (
          <button
            key={getKey(option)}
            className={styles.button}
            onClick={() => {
              setOpen(false);
              if (onSelect) {
                onSelect(option);
              }
            }}
            disabled={getDisabled ? getDisabled(option) : false}
          >
            {render(option)}
          </button>
        );
      })}
    </BasePopup>
  );
}

export default Dropdown;
