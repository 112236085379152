import React from "react";
import { classes } from "lib/helpers";
import styles from "./Spinner.module.scss";

function Spinner({ overlay = false, small = false }) {
  return (
    <div
      className={overlay ? classes(styles.root, styles.overlay) : styles.root}
    >
      <svg
        viewBox="0 0 70 70"
        fill="none"
        strokeLinecap="round"
        strokeWidth="5"
        className={small ? styles.small : ""}
      >
        <path d="m22.144 19.679a20 20 0 0 1 25.712 0" />
        <path d="m47.856 50.321a20 20 0 0 1-25.712 0" />
      </svg>
    </div>
  );
}

export default Spinner;
