import * as yup from "yup";
import React, { useState } from "react";
import Form from "components/Form";
import { Link } from "react-router-dom";
import OtherErrors from "components/OtherErrors";
import Password from "components/Password";
import { T } from "lib/language";
import { resetPasswordConfirm } from "api/resetPassword";
import styles from "./ResetPassword.module.scss";
import { useQueryParams } from "lib/helpers";

export const formSchema = yup
  .object({
    password: yup.string().required(),
    password_confirm: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), undefined], "Passwords must match"),
  })
  .required();

function ResetPassword() {
  const [submitted, setSubmitted] = useState(false);
  const query = useQueryParams();

  return (
    <>
      <div className={styles.titleGroup}>
        <h1>
          <T id="resetPassword" />
        </h1>
      </div>
      {submitted ? (
        <>
          <p className={styles.success}>
            <T id="resetPassword.confirm.success" />
          </p>
          <Link className="ctaLink" to="/">
            <T id="goToLogin" />
          </Link>
        </>
      ) : (
        <Form
          className={styles.form}
          schema={formSchema}
          onSubmit={async (data) => {
            await resetPasswordConfirm({
              token: query.get("token") || "invalid token",
              password: data.password,
            });
            setSubmitted(true);
          }}
        >
          <Password name="password">
            <T id="password.new" />
          </Password>
          <Password name="password_confirm">
            <T id="password.confirm" />
          </Password>
          <OtherErrors />
          <button type="submit" className="cta medium">
            <T id="submit" />
          </button>
        </Form>
      )}
    </>
  );
}

export default ResetPassword;
