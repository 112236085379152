import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./Checkbox.module.scss";

type props = JSX.IntrinsicElements["input"] & {
  children?: ReactNode;
  onChecked?: (checked: boolean) => void;
  indeterminate?: boolean;
};

function Checkbox({ children, onChecked, indeterminate, ...props }: props) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate || false;
    }
  }, [ref, indeterminate]);

  return (
    <label className={styles.root}>
      <input
        ref={ref}
        type="checkbox"
        onChange={(e) => {
          if (onChecked) {
            onChecked(e.target.checked);
          }
        }}
        {...props}
      />
      {children ? <div className={styles.content}>{children}</div> : null}
    </label>
  );
}

export default Checkbox;
