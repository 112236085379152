import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import { classes } from "lib/helpers";
import styles from "./FormInput.module.scss";

export type Props<O> = Omit<JSX.IntrinsicElements["select"], "value"> & {
  styleSize?: "large" | "medium";
  options: readonly O[];
  value?: O;
  onValue?: (o: O) => void;
  render?: (o: O) => any;
};

function FormSelect<O>({
  styleSize = "large",
  options,
  value,
  onValue,
  render,
  children,
  ...props
}: Props<O>) {
  const valueCast = useMemo(() => {
    const index = options.findIndex((o) => o === value);
    return index < 0 ? 0 : index;
  }, [options, value]);
  const onChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const index = parseInt(e.target.value) || 0;
      if (onValue && options.length) {
        onValue(options[index]);
      }
    },
    [onValue, options]
  );

  return (
    <label
      className={classes(styles.root, styleSize === "medium" && styles.medium)}
    >
      {children}
      <select value={valueCast} onChange={onChange} {...props}>
        {options.map((o, i) => (
          <option key={i} value={i}>
            {render ? render(o) : o}
          </option>
        ))}
      </select>
    </label>
  );
}

export default FormSelect;
