import * as yup from "yup";
import { api } from "lib/api";

export const deviceCorrectionInput = yup.object({
  uuid: yup.string().uuid().required(),
  device: yup.number().required(),
  calibration: yup.string().required(),
  protein_offset: yup.number().required(),
  moisture_offset: yup.number().required(),
  oil_offset: yup.number().required(),
  gluten_iso_offset: yup.number().required(),
  gluten_gost_offset: yup.number().required(),
  add_area_correction: yup.boolean().required(),
});
export const createDeviceCorrection = api("POST", "v1/deviceCorrection/")
  .inputSchema(deviceCorrectionInput)
  .call();

const device = yup
  .object({
    serial_number: yup.number().required(),
    device_model: yup.string().required(),
    calibrations: yup.array().of(yup.string().uuid()).nullable(),
  })
  .required();
export type Device = yup.Asserts<typeof device>;
const devicesOutput = yup.array(device).required();
export const useDevices = api("GET", "v2/devices/").output(devicesOutput).use();

const deviceCorrection = yup.object({
  protein_offset: yup.number().required(),
  moisture_offset: yup.number().required(),
  oil_offset: yup.number().required(),
  gluten_iso_offset: yup.number().required(),
  gluten_gost_offset: yup.number().required(),
  add_area_correction: yup.boolean().required(),
});
export type DeviceCorrection = yup.Asserts<typeof deviceCorrection>;
export const initialCorrection: DeviceCorrection = {
  protein_offset: 0,
  moisture_offset: 0,
  oil_offset: 0,
  gluten_iso_offset: 0,
  gluten_gost_offset: 0,
  add_area_correction: true,
};

const correctionsOutput = yup
  .object({
    area_correction: yup
      .object({
        protein_offset: yup.number().required(),
        moisture_offset: yup.number().required(),
        oil_offset: yup.number().required(),
      })
      .nullable(),
    device_correction: deviceCorrection.nullable(),
  })
  .required();
export const useCorrection = api("GET", "v2/corrections/")
  .input<{ calibration: string; device: number }>()
  .output(correctionsOutput)
  .use();
