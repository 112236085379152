import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/tr";
import React, { ReactNode } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DayPickerInputProps } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { classes } from "lib/helpers";
import styles from "./FormInput.module.scss";
import { useIntl } from "react-intl";

export type Props = Omit<DayPickerInputProps, "value" | "onChange"> & {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  children?: ReactNode;
  styleSize?: "large" | "medium";
  type: "start" | "end";
};

function DateInput({
  styleSize = "large",
  value,
  onChange,
  children,
  type,
  ...props
}: Props) {
  const intl = useIntl();
  return (
    <div
      className={classes(styles.root, styleSize === "medium" && styles.medium)}
    >
      {children}
      <DayPickerInput
        value={value}
        dayPickerProps={{
          locale: intl.locale,
          localeUtils: MomentLocaleUtils,
        }}
        formatDate={MomentLocaleUtils.formatDate}
        parseDate={MomentLocaleUtils.parseDate}
        format="L"
        placeholder=""
        onDayChange={(date) => {
          if (date instanceof Date) {
            const timeZoneOffset = date.getTimezoneOffset() || 0;
            if (type === "start") {
              date.setHours(0, 0 - timeZoneOffset, 0, 0);
            } else if (type === "end") {
              date.setHours(23, 59 + timeZoneOffset, 0, 0);
            }
            onChange(date);
          } else {
            onChange(undefined);
          }
        }}
        {...props}
      />
    </div>
  );
}

export default DateInput;
