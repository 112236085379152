import React, { useCallback, useMemo } from "react";
import { Pagination as Input } from "api/measurements";
import { T } from "lib/language";
import { classes } from "lib/helpers";
import imgFirst from "images/pagination/first.svg";
import imgLast from "images/pagination/last.svg";
import imgNext from "images/pagination/next.svg";
import imgPrevious from "images/pagination/previous.svg";
import styles from "./Pagination.module.scss";
import { useIntl } from "react-intl";

function Offset({
  count,
  limit,
  offset,
  onOffset,
}: {
  count: number;
  limit: number;
  offset: number;
  onOffset: (offset: number) => void;
}) {
  const currentPage = Math.floor(offset / limit);
  const firstPage = 0;
  const lastMeasurement = count ? count - 1 : 0;
  const lastPage = Math.floor(lastMeasurement / limit);
  const startPage = Math.max(firstPage, currentPage - 2);
  const endPage = Math.min(startPage + 4, lastPage);
  const previousPage = Math.max(firstPage, currentPage - 1);
  const nextPage = Math.min(lastPage, currentPage + 1);

  const pages = useMemo(() => {
    return Array.from(Array(endPage - startPage + 1), (_, i) => {
      const page = i + startPage;
      return {
        page,
        number: page + 1,
      };
    });
  }, [endPage, startPage]);

  const changePage = useCallback(
    (page: number) => onOffset(page * limit),
    [limit, onOffset]
  );

  return (
    <div className={styles.offset}>
      <button
        className={styles.secondary}
        onClick={() => changePage(firstPage)}
      >
        <img src={imgFirst} alt="first" />
      </button>
      <button
        className={styles.primary}
        onClick={() => changePage(previousPage)}
      >
        <img src={imgPrevious} alt="previous" />
      </button>
      {pages?.map(({ page, number }) => (
        <button
          key={page}
          className={classes(
            styles.page,
            currentPage === page && styles.current
          )}
          onClick={() => changePage(page)}
        >
          {number}
        </button>
      ))}
      <button className={styles.primary} onClick={() => changePage(nextPage)}>
        <img src={imgNext} alt="next" />
      </button>
      <button className={styles.secondary} onClick={() => changePage(lastPage)}>
        <img src={imgLast} alt="last" />
      </button>
    </div>
  );
}

function Limit({
  limit,
  onLimit,
}: {
  limit: number | undefined;
  onLimit: (limit: number | undefined) => void;
}) {
  const intl = useIntl();
  return (
    <label className={styles.limit}>
      <T id="measurements.pagination.showPerPage" />:
      <select
        value={limit}
        onChange={(e) => {
          const newLimit = parseInt(e.target.value);
          return onLimit(isNaN(newLimit) ? undefined : newLimit);
        }}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={undefined}>
          {intl.formatMessage({ id: "measurements.pagination.all" })}
        </option>
      </select>
    </label>
  );
}

function Pagination({
  count,
  pagination: { limit, offset },
  onPagination,
}: {
  count: number;
  pagination: Input;
  onPagination: (pagination: Input) => void;
}) {
  return (
    <div className={styles.root}>
      {limit ? (
        <Offset
          count={count}
          limit={limit}
          offset={offset}
          onOffset={(offset) => onPagination({ limit, offset })}
        />
      ) : null}
      <span>
        <T id="measurements.pagination.total" />
        {": "}
        {count}
      </span>
      <Limit
        limit={limit}
        onLimit={(limit) => onPagination({ limit, offset: 0 })}
      />
    </div>
  );
}
export default Pagination;
