import * as yup from "yup";
import { api } from "lib/api";

export const input = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  telephone: yup.string(),
});

const inputRoles = yup.object({ roles: yup.array() });

const choice = yup.object({
  uuid: yup.string().required(),
  name_tr: yup.string().required(),
});

export type Choice = yup.Asserts<typeof choice>;

const role = yup.object({
  uuid: yup.string().required(),
  name_tr: yup.string().required(),
  is_multi_choice: yup.boolean().nullable(),
  choices: yup.array(choice).required(),
});

export type Role = yup.Asserts<typeof role>;

const rolesArray = yup.array(role);

const output = yup.object({
  first_name: yup.string(),
  last_name: yup.string(),
  telephone: yup.string(),
  is_vad: yup.boolean(),
  roles: yup.array(),
});

export const useAccount = api("GET", "v1/account/").output(output).use();
export const updateAccount = api("PATCH", "v1/account/")
  .inputSchema(input)
  .output(output)
  .call();

export const updateRoles = api("PATCH", "v1/account/")
  .inputSchema(inputRoles)
  .output(output)
  .call();

export const changePasswordInput = yup.object({
  current_password: yup.string().required(),
  new_password: yup.string().required(),
  password_confirm: yup
    .string()
    .required()
    .oneOf([yup.ref("new_password"), undefined], "Passwords must match"),
});

export const changePassword = api("POST", "v1/account/password/change/")
  .inputSchema(changePasswordInput)
  .call();

const verifyInput = yup.object({
  email: yup.string().email().required(),
  token: yup.string().required(),
});

export const useVerify = api("POST", "v1/registration/confirm/")
  .inputSchema(verifyInput)
  .use();

export const useRoles = api("GET", "v1/roles/").output(rolesArray).use();
