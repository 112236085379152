import React, { ReactNode } from "react";
import { T } from "lib/language";
import { classes } from "lib/helpers";
import styles from "./Modal.module.scss";

function Modal({
  onClose,
  title,
  children,
  buttons,
  closeContent,
}: {
  onClose: () => void;
  title: ReactNode;
  buttons?: ReactNode;
  closeContent?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <div className={styles.root} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <svg
          className={styles.closeButton}
          onClick={onClose}
          width="10"
          height="10"
          version="1.1"
          viewBox="0 0 10 10"
        >
          <path d="m8.59 0-3.59 3.59-3.59-3.59-1.41 1.41 3.59 3.59-3.59 3.59 1.41 1.41 3.59-3.59 3.59 3.59 1.41-1.41-3.59-3.59 3.59-3.59z" />
        </svg>
        {title ? (
          <h3 className={classes(styles.title, !children && styles.noContent)}>
            {title}
          </h3>
        ) : null}
        {children}
        <div className={styles.buttonGroup}>
          {buttons ? (
            <button className="cta medium secondary" onClick={onClose}>
              {closeContent ? closeContent : <T id="cancel" />}
            </button>
          ) : (
            <button className="cta medium" onClick={onClose}>
              {closeContent ? closeContent : <T id="close" />}
            </button>
          )}

          {buttons}
        </div>
      </div>
    </div>
  );
}

export default Modal;
