import * as yup from "yup";
import React, {
  FormEventHandler,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApiError } from "../lib/api";
import { formatErrors } from "../lib/helpers";

type FormData = {
  otherErrors: string[];
  schemas: Record<string, yup.AnySchema | undefined>;
};

const emptyFormData: FormData = {
  otherErrors: [],
  schemas: {},
};

const FormContext = createContext(emptyFormData);

function addErrorToField(
  form: HTMLFormElement,
  name: string | undefined,
  error: string | undefined
) {
  if (!error) {
    return true;
  }
  if (!name) {
    return false;
  }
  const element = form.querySelector(`[name='${name}']`);
  if (!element || !(element instanceof HTMLInputElement)) {
    return false;
  }
  element.setCustomValidity(error);
  element.checkValidity();
  return true;
}

function focusFirstInvalid(form: HTMLFormElement) {
  const element = form.querySelector<HTMLInputElement>("input:invalid");
  element?.focus();
}

export default function Form<S extends yup.AnyObjectSchema>({
  children,
  schema,
  onSubmit,
  ...params
}: Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  schema: S;
  onSubmit: (data: S["__outputType"]) => any;
}) {
  const [form, setForm] = useState<FormData>({
    ...emptyFormData,
    schemas: schema.fields,
  });

  const [destroyed, setDestroyed] = useState(false);
  useEffect(() => () => setDestroyed(true), []);

  const [submitting, setSubmitting] = useState(false);

  const submitCB = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const form = e.currentTarget;

      if (!form.checkValidity()) {
        focusFirstInvalid(form);
        return;
      }

      const formData = Object.fromEntries(new FormData(form).entries());

      let validatedData: S["__outputType"];
      try {
        validatedData = schema.validateSync(formData, { abortEarly: false });
      } catch (e) {
        const otherErrors = (e as yup.ValidationError).inner.flatMap((e) => {
          if (addErrorToField(form, e.path, e.message)) {
            return [];
          } else {
            return [e.message];
          }
        });
        focusFirstInvalid(form);
        setForm((form) => ({ ...form, otherErrors }));
        return;
      }

      setSubmitting(true);
      try {
        await onSubmit(validatedData);
      } catch (error) {
        if (destroyed) {
          throw error;
        }

        setSubmitting(false);

        if (error instanceof ApiError) {
          const { errors, otherErrors } = error;
          const uncaught = Object.entries(errors).flatMap(([name, errors]) => {
            if (addErrorToField(form, name, formatErrors(errors))) {
              return [];
            } else {
              return errors || [];
            }
          });
          focusFirstInvalid(form);
          setForm((form) => ({
            ...form,
            otherErrors: [...otherErrors, ...uncaught],
          }));
        } else {
          throw error;
        }
      }

      if (!destroyed) {
        setSubmitting(false);
      }
    },
    [destroyed, onSubmit, schema]
  );

  return (
    <form onSubmit={submitCB} noValidate {...params}>
      <fieldset style={{ display: "contents" }} disabled={submitting}>
        <FormContext.Provider value={form}>{children}</FormContext.Provider>
      </fieldset>
    </form>
  );
}

function schemaToType(schema?: yup.AnySchema) {
  if (schema instanceof yup.NumberSchema) {
    return "number";
  } else if (schema instanceof yup.BooleanSchema) {
    return "checkbox";
  } else if (schema instanceof yup.DateSchema) {
    return "date";
  } else if (schema instanceof yup.StringSchema) {
    return schemaIs(schema, "email") ? "email" : "text";
  } else {
    return undefined;
  }
}

function schemaIs(schema: yup.AnySchema | undefined, test: string) {
  return schema
    ? schema.describe().tests.findIndex(({ name }) => name === test) >= 0
    : false;
}

function schemaIsRequired(schema: yup.AnySchema | undefined) {
  if (schema instanceof yup.BooleanSchema) {
    return false;
  }
  return schemaIs(schema, "required");
}

export function useForm() {
  return useContext(FormContext);
}

type FieldProps = {
  type?: string;
  required: boolean;
};

export function useField(name: string = ""): FieldProps {
  const {
    schemas: { [name]: schema },
  } = useForm();

  return useMemo<FieldProps>(() => {
    return {
      type: schemaToType(schema),
      required: schemaIsRequired(schema),
    };
  }, [schema]);
}
