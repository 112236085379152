import * as Sentry from "@sentry/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import LoggedOut from "./LoggedOut";
import React from "react";
import { useSession } from "lib/session";

function Application() {
  const { token } = useSession();
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/verify"
          render={(props) => {
            return (
              <Redirect
                to={{
                  pathname: "/login/verify",
                  search: props.location.search,
                }}
              />
            );
          }}
        />
        <Route
          exact
          path="/reset_password/confirm"
          render={(props) => {
            return (
              <Redirect
                to={{
                  pathname: "/login/reset-password/confirm",
                  search: props.location.search,
                }}
              />
            );
          }}
        />
        <Route path="/login">
          {token ? <Redirect to="/measurements" /> : <LoggedOut />}
        </Route>
        <Route>{token ? <Dashboard /> : <Redirect to="/login" />}</Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(Application);
