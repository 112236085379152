import { FormattedDate, FormattedTime } from "react-intl";
import {
  Measurement,
  MeasurementGroup,
  RangeValues,
  getRangeValues,
  useMeasurementGroup,
} from "api/measurements";
import { classes, formatPercentage } from "lib/helpers";
import { CalibrationText } from "lib/calibrations";
import { T } from "lib/language";
import styles from "./GroupDetails.module.scss";
import stylesDetailsCommon from "./DetailsCommon.module.scss";
import { useAccount } from "api/account";
import { useParams } from "react-router-dom";

function Basis({ basis }: { basis: number | null }) {
  function BasisText() {
    if (basis === null) {
      return <T id="measurement.wet" />;
    } else if (basis === 0) {
      return <T id="measurement.dry" />;
    } else {
      return <T id="measurement.fixed" values={{ basis }} />;
    }
  }
  return (
    <small>
      <BasisText />
    </small>
  );
}

function DetailsCollapse({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  return <MeasurementsListContainer measurementGroup={m} />;
}

function MeasurementsListContainer({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  let distinctGpsLocation = m.measurements.filter(
    (a, i) =>
      m.measurements.findIndex(
        (s) =>
          a.gps_latitude === s.gps_latitude &&
          a.gps_longitude === s.gps_longitude
      ) === i
  );

  return (
    <div className={styles.measurementsContainerReport}>
      <div className={styles.coordinates}>
        <T id="measurement.details.printableReport.coordinates" />
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <T id="measurement.details.printableReport.latitude" />
            </th>
            <th>
              <T id="measurement.details.printableReport.longitude" />
            </th>
          </tr>
        </thead>
        <tbody>
          {distinctGpsLocation.map((item: Measurement) => (
            <tr key={item.uuid}>
              <td>{item.gps_latitude?.toFixed(4)}</td>
              <td>{item.gps_longitude?.toFixed(4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function RangeText({
  rangeValues: values,
  compound,
}: {
  rangeValues: RangeValues | null;
  compound: string;
}) {
  if (values === null) return null;

  const minMax = [];

  switch (compound) {
    case "protein":
      minMax[0] = values.protein.min;
      minMax[1] = values.protein.max;
      break;
    case "carbohydrate":
      minMax[0] = values.carbohydrate.min;
      minMax[1] = values.carbohydrate.max;
      break;
    case "moisture":
      minMax[0] = values.moisture.min;
      minMax[1] = values.moisture.max;
      break;
    case "oil":
      minMax[0] = values.oil.min;
      minMax[1] = values.oil.max;
      break;
  }

  return (
    <small>
      <T id="measurement.range" /> {formatPercentage(minMax[0], "")} -{" "}
      {formatPercentage(minMax[1])}
    </small>
  );
}

function Comment({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  if (m.comment !== undefined && m.comment?.length === 0) return null;

  return (
    <div className={styles.reportComment}>
      <div className={styles.commentTitle}>
        <T id="measurement.comment" />
      </div>
      <div>{m.comment}</div>
    </div>
  );
}

function DetailsLoaded({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  return (
    <div
      className={classes(
        stylesDetailsCommon.details,
        stylesDetailsCommon.detailsReport
      )}
    >
      {m.groupName !== undefined && m.groupName.length > 0 ? (
        <h2>{m.groupName}</h2>
      ) : null}
      <div className={stylesDetailsCommon.title}>
        <h3>
          <CalibrationText calibration={m.calibration} />
        </h3>
        <div className={stylesDetailsCommon.info}>
          <FormattedDate value={m.measured_at} />
          <br />
          <FormattedTime timeStyle={"medium"} value={m.measured_at} />
        </div>
        <div className={stylesDetailsCommon.info}>
          {m.measurements.length > 0 ? (
            <>
              <T id="measurements.details.gsAnalyzerSN" />:
              <br />
              {m.measurements[0].device}
            </>
          ) : null}
        </div>
        <div className={stylesDetailsCommon.info}>
          <>
            <T id="measurement.details.printableReport.numberOfMeasurements" />:
            <br />
            {m.measurements.length}
          </>
        </div>
      </div>
      <div className={stylesDetailsCommon.bodyReport}>
        <div className={stylesDetailsCommon.dataReport}>
          <Compounds measurementGroup={m} />
          <DetailsCollapse measurementGroup={m} />
          <Comment measurementGroup={m} />
        </div>
      </div>
    </div>
  );
}

function Compounds({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  const rangeValues = getRangeValues(m);

  return (
    <ul>
      <li>
        <div>
          <T id="measurement.protein" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].protein_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.protein)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"protein"} />
          ) : null}
        </div>
      </li>
      <li>
        <div>
          <T id="measurement.moisture" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].moisture_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.moisture)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"moisture"} />
          ) : null}
        </div>
      </li>
      <li>
        <div>
          <T id="measurement.carbohydrate" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].carbohydrate_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.carbohydrate)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"carbohydrate"} />
          ) : null}
        </div>
      </li>
      <li>
        <div>
          <T id="measurement.oil" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].oil_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.oil)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"oil"} />
          ) : null}
        </div>
      </li>
    </ul>
  );
}

function getDateTime() {
  let dt = new Date();

  return (
    <>
      <T id="measurement.details.printableReport.printedAt" />{" "}
      {dt.toLocaleDateString()} {dt.toLocaleTimeString()} UTC+
      {Math.abs(dt.getTimezoneOffset()) / 60}
    </>
  );
}

function UserInfo() {
  const [account] = useAccount();

  return (
    <div className={styles.reportUserInfo}>
      <table>
        <tbody>
          <tr>
            <td>
              {account?.first_name} {account?.last_name}
            </td>
            <td>{getDateTime()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function GroupDetailsPrintableReport() {
  const { uuid } = useParams<{ uuid: string }>();
  const [measurementGroup, , errors] = useMeasurementGroup(uuid);

  if (measurementGroup === undefined) {
    return (
      <div className={stylesDetailsCommon.errormessage}>
        <b>Server responded: </b>
        <code>{errors.length > 0 ? errors[0] : ""}</code>
      </div>
    );
  } else {
    return (
      <div className={classes(stylesDetailsCommon.root, styles.watermark)}>
        <div
          className={classes(
            stylesDetailsCommon.detailsContainer,
            stylesDetailsCommon.detailsContainerReport
          )}
        >
          <UserInfo />
          <DetailsLoaded measurementGroup={measurementGroup} />
        </div>
      </div>
    );
  }
}

export default GroupDetailsPrintableReport;
