import FormInput, { Props } from "./FormInput";
import { ReactComponent as HideIcon } from "images/passwordHide.svg";
import { ReactComponent as ShowIcon } from "images/passwordShow.svg";
import styles from "./Password.module.scss";
import { useState } from "react";

function Password({ type, children, ...props }: Props) {
  const [hidden, setHidden] = useState(true);
  return (
    <FormInput type={type || hidden ? "password" : "text"} {...props}>
      {children}
      <div
        className={styles.toggle}
        onClick={(e) => {
          e.preventDefault();
          setHidden((hidden) => !hidden);
        }}
      >
        {hidden ? <ShowIcon /> : <HideIcon />}
      </div>
    </FormInput>
  );
}

export default Password;
