import "style/index.scss";
import * as Sentry from "@sentry/react";
import Application from "templates/Application";
import { BrowserTracing } from "@sentry/tracing";
import { LanguageProvider } from "lib/language";
import { MapsProvider } from "lib/map";
import { MessageProvider } from "lib/message";
import React from "react";
import ReactDOM from "react-dom";
import { SessionProvider } from "lib/session";

if (
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "staging"
) {
  Sentry.init({
    dsn: "https://6ab7bcebc0fa49e8a17c8a6d52b0034d@o323240.ingest.sentry.io/6659259",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  });
}

ReactDOM.render(
  <MapsProvider>
    <SessionProvider>
      <LanguageProvider>
        <MessageProvider>
          <Application />
        </MessageProvider>
      </LanguageProvider>
    </SessionProvider>
  </MapsProvider>,
  document.getElementById("root")
);
