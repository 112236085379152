import { Redirect, Route, Switch } from "react-router-dom";
import Confirm from "pages/resetPassword/Confirm";
import Login from "pages/login/Login";
import React from "react";
import ResetPassword from "pages/resetPassword/ResetPassword";
import Verify from "pages/verify/Verify";
import logo from "images/logo.svg";
import logoText from "images/logoText.svg";
import styles from "./LoggedOut.module.scss";

function LoggedOut() {
  return (
    <>
      <header className={styles.header}>
        <img className={styles.logo} src={logo} alt="Grainsense Logo" />
        <img className={styles.logoText} src={logoText} alt="Grainsense" />
      </header>
      <main className={styles.main}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/login/reset-password/confirm">
            <Confirm />
          </Route>
          <Route exact path="/login/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/login/verify">
            <Verify />
          </Route>
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </main>
    </>
  );
}

export default LoggedOut;
