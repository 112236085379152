import { login, loginInput } from "api/login";
import Form from "components/Form";
import FormInput from "components/FormInput";
import LanguageSelector from "components/popup/LanguageSelector";
import { Link } from "react-router-dom";
import OtherErrors from "components/OtherErrors";
import Password from "components/Password";
import React from "react";
import { T } from "lib/language";
import { create_account_url } from "config";
import styles from "./Login.module.scss";
import { useSession } from "lib/session";

function Login() {
  const { logIn } = useSession();

  return (
    <>
      <div className={styles.titleGroup}>
        <h1>
          <T id="login.header" />
        </h1>
        <LanguageSelector />
      </div>
      <Form
        schema={loginInput}
        onSubmit={async (data) => {
          const session = await login(data);
          logIn(session);
        }}
      >
        <FormInput name="email">
          <T id="email" />
        </FormInput>
        <Password name="password">
          <T id="password" />
        </Password>
        <OtherErrors />
        <div className={styles.ctaGroup}>
          <button type="submit" className="cta">
            <T id="login.submit" />
          </button>
          <Link className="ctaLink" to="/login/reset-password">
            <T id="resetPassword" />
          </Link>
          <a
            className="ctaLink"
            href={create_account_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <T id="createAccount" />
          </a>
        </div>
      </Form>
    </>
  );
}

export default Login;
