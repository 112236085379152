import * as yup from "yup";
import { api } from "lib/api";

const output = yup
  .object({
    licence_id: yup.string().nullable(),
    product_name: yup.string(),
    amount: yup.array(),
  })
  .transform((value, original) =>
    original === null || original === "" ? undefined : value
  );

const input = yup.object({
  product_id: yup.number(),
});

export const getUserLicence = api("GET", "v3/webshop/user/")
  .output(output)
  .use();

export const changeUserLicence = api("PUT", "v3/webshop/update/")
  .inputSchema(input)
  .call();

export type licenceInfo = yup.Asserts<typeof output>;
