import React, { useEffect, useState } from "react";
import { changeUserLicence, getUserLicence, licenceInfo } from "api/webshop";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import styles from "./Webshop.module.scss";
import useMessage from "lib/message";

function Webshop() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentlicence] = getUserLicence();

  // todo del ---------------------------------------
  useEffect(() => {
    console.log(currentlicence);
  }, [currentlicence]);

  if (!currentlicence) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageSection}>
      <h1>
        <T id="webshop" />
      </h1>
      <div className={styles.pageTop}>
        {isLoading ? (
          <div className={styles.Spinner}>
            <Spinner />
          </div>
        ) : null}
        <div className={styles.gridContainer}>
          {currentlicence.amount !== undefined &&
          currentlicence.licence_id !== undefined ? (
            <Card currentlicence={currentlicence} setIsloading={setIsLoading} />
          ) : (
            <T id="webshop.error.message1" />
          )}
        </div>
      </div>
    </div>
  );
}

function Card({
  currentlicence: currentLicence,
  setIsloading: setisloading,
}: {
  currentlicence: licenceInfo;
  setIsloading: (value: boolean) => void;
}) {
  const [state, setState] = useState(Number(currentLicence.licence_id));
  const [modalOpened, setModalOpened] = useState(false);
  const [downgradeModalOpened, setDowngradeModalOpened] = useState(false);
  const [price, setPrice] = useState(250);
  const sendMessage = useMessage();
  const min = 1;
  const max = 10;

  useEffect(() => {
    [currentLicence].filter((element) =>
      element?.amount?.map((key) =>
        key.max_devices === state ? setPrice(key.price) : null
      )
    );
  }, [currentLicence, state]);

  let handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = Math.max(
      min,
      Math.min(max, Number((e.target as HTMLInputElement).value))
    );
    setState(value);
  };

  const changeLicence = () => {
    setisloading(true);
    changeUserLicence({ product_id: Number(state) })
      .then(function () {
        sendMessage(<T id="webshop.change.licence.success" />);
      })
      .catch(function () {
        sendMessage(<T id="webshop.change.licence.fail" />);
      })
      .finally(function () {
        setisloading(false);
        setModalOpened(false);
      });
  };

  return (
    <div className={styles.card}>
      <div className={styles.card__body}>
        <h2 className={styles.card__title}>
          <T id="webshop.card.title" />
        </h2>
        <p className={styles.card__description}>
          <T id="webshop.card.description" />
        </p>
        <div className={styles.card__tablewrappper}>
          <ul className={styles.card__table}>
            <li>
              <span>
                <T id="webshop.card.tableText1" />
              </span>
            </li>
            <li>
              <span>
                <T id="webshop.card.tableText2" />
              </span>
            </li>
            <li>
              <span>
                <T id="webshop.card.tableText3" />
              </span>
            </li>
            <li>
              <span>
                <T id="webshop.card.tableText4" />
              </span>
            </li>
          </ul>
        </div>
        <p className={styles.card__valid}>
          <T id="webshop.card.subscription" />
        </p>
      </div>
      <div className={styles.card__body2}>
        <h3 className={styles.card__price}>
          {price}
          &euro;
        </h3>
        <h3>
          <T id="webshop.card.vat" />
        </h3>
        <h3>
          <T id="webshop.card.analyzer" />
        </h3>
        <button
          className={styles.card__btn}
          onClick={() => {
            if (state < Number(currentLicence.licence_id)) {
              setDowngradeModalOpened(true);
            } else {
              setModalOpened(true);
            }
          }}
        >
          <T id="webshop.card.button" />
        </button>
        <p className={styles.card__productcode}>
          <T id="webshop.card.productCode" />
          <br />
          <b>{currentLicence.product_name}</b>
        </p>
        <div>
          <input
            type="number"
            className={styles.card__input}
            value={state}
            placeholder="1"
            onChange={(e) => handleChange(e)}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />
          <label className={styles.card__label}>
            <T id="webshop.card.analyzer.connected" />
          </label>
        </div>
      </div>

      {modalOpened ? (
        <Modal
          title={<T id="webshop.modal.title" />}
          onClose={() => setModalOpened(false)}
          buttons={
            <button
              className="cta medium"
              onClick={() => {
                changeLicence();
              }}
            >
              <T id="webshop.modal.proceed1" />
            </button>
          }
        >
          <T id="webshop.modal.proceed2" />
        </Modal>
      ) : null}

      {downgradeModalOpened ? (
        <Modal
          title={<T id="webshop.modal.title" />}
          onClose={() => setDowngradeModalOpened(false)}
        >
          <T id="webshop.modal.downgrade" />
        </Modal>
      ) : null}
    </div>
  );
}

export default Webshop;
