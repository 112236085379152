import { AnySchema, BaseSchema } from "yup";
import { Key, compile, pathToRegexp } from "path-to-regexp";
import { useEffect, useState } from "react";
import { api as apiPath } from "config";
import download from "downloadjs";
import { getCookie } from "./helpers";

export class ApiError<K extends keyof any> extends Error {
  errors: { [Key in K]?: string[] };
  otherErrors: string[];

  constructor(errors: { [Key in K]?: string[] }, otherErrors: string[]) {
    super("Api error");
    this.errors = errors;
    this.otherErrors = otherErrors;
  }
}

function unexpectedError<K extends keyof any>(response: Response) {
  return new ApiError<K>({}, [response.statusText]);
}

type Method = "GET" | "POST" | "PUT" | "HEAD" | "DELETE" | "PATCH" | "OPTIONS";

function getUrlParams(data: unknown) {
  function castUrlParam(param: any) {
    return param instanceof Date ? param.toISOString() : param;
  }

  if (typeof data === "object" && data !== null && !Array.isArray(data)) {
    return new URLSearchParams(
      Object.entries(data)
        .filter(([k, v]) => v !== undefined)
        .map(([k, v]) => [k, castUrlParam(v)])
    ).toString();
  }
  return [new URLSearchParams(String(data))];
}

function getUrlWithArg(url: string, arg?: string) {
  if (!arg) {
    return url;
  }
  let keys = [] as Key[];
  pathToRegexp(url, keys);
  const [{ name } = { name: "id" }] = keys;

  return compile(url)({ [name]: arg });
}

function createRequest(
  method: Method,
  url: string,
  arg?: string,
  data?: unknown
): Request {
  const token = getCookie("token");
  const locale = getCookie("locale");

  const urlWithArg = getUrlWithArg(url, arg);

  const hasBody = method !== "GET" && method !== "HEAD";
  const urlParams = !hasBody && data ? "?" + getUrlParams(data) : "";
  const body = hasBody && data ? JSON.stringify(data) : undefined;

  // File data cannot be converted to string
  const attachment = (data as any)?.attachment;
  if (attachment !== undefined) {
    const formdata = new FormData();
    formdata.append("attachment", attachment);

    return new Request(apiPath + urlWithArg + urlParams, {
      referrerPolicy: "no-referrer",
      method: method,
      headers: {
        ...(token ? { Authorization: "JWT " + token } : {}),
        ...(locale ? { "Accept-Language": locale } : {}),
      },
      body: formdata,
    });
  } else {
    return new Request(apiPath + urlWithArg + urlParams, {
      cache: "no-cache",
      referrerPolicy: "no-referrer",
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: "JWT " + token } : {}),
        ...(locale ? { "Accept-Language": locale } : {}),
      },
      body,
    });
  }
}

class VoidSchema extends BaseSchema<void, any, void> {}

async function callApi<OutS extends AnySchema>(
  method: Method,
  url: string,
  outSchema: OutS,
  hasDownload: boolean,
  arg: string | undefined,
  data: unknown
): Promise<OutS["__outputType"]> {
  const response = await fetch(createRequest(method, url, arg, data));

  if (hasDownload && response.ok) {
    if (response.status === 204) {
      return;
    }

    const blob = await response.blob();
    const mimeType = response.headers.get("content-type") || undefined;
    const filename =
      response.headers.get("content-disposition")?.split("filename=")[1] ||
      undefined;

    download(blob, filename, mimeType);
    return;
  }

  if (outSchema instanceof VoidSchema && response.ok) {
    return;
  }

  let json = null;
  try {
    json = await response.json();
  } catch (error) {}

  if (response.ok) {
    return outSchema.validate(json);
  }

  if (typeof json === "object" && json !== null && !Array.isArray(json)) {
    const { non_field_errors = [], ...fieldErrors } = { ...json };
    throw new ApiError(fieldErrors, non_field_errors);
  }

  throw unexpectedError(response);
}

function createApiHook(
  callApi: (arg: string | undefined, data: unknown) => Promise<unknown>
) {
  return function useApi(
    arg: string | undefined,
    data: unknown
  ): [unknown, boolean, string[]] {
    const [state, setState] = useState<unknown>(undefined);
    const [requested, setRequested] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
      setRequested(true);
    }, [arg, data]);

    useEffect(() => {
      if (requested && !loading) {
        setRequested(false);
        setLoading(true);
        callApi(arg, data)
          .then((result) => {
            setState(result);
            setLoading(false);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              const { errors, otherErrors } = error;
              const fieldErrors = Object.entries(errors).flatMap(
                ([name, errors]) => errors || []
              );
              setErrors([...otherErrors, ...fieldErrors]);
              setLoading(false);
            } else {
              throw error;
            }
          });
      }
    }, [arg, data, requested, loading]);

    return [state, loading, errors];
  };
}

type ApiCall<Arg extends undefined | string, In, Out> = In extends undefined
  ? Arg extends undefined
    ? () => Promise<Out>
    : (arg: Arg) => Promise<Out>
  : Arg extends undefined
  ? (data: In) => Promise<Out>
  : (arg: Arg, data: In) => Promise<Out>;

type ApiHook<Arg extends undefined | string, In, Out> = In extends undefined
  ? Arg extends undefined
    ? () => [Out | undefined, boolean, string[]]
    : (arg: Arg) => [Out | undefined, boolean, string[]]
  : Arg extends undefined
  ? (data: In) => [Out | undefined, boolean, string[]]
  : (arg: Arg, data: In) => [Out | undefined, boolean, string[]];

class Api<Arg extends undefined | string, In, OutS extends AnySchema> {
  private readonly method: Method;
  private readonly url: string;
  private readonly outSchema: OutS;
  private readonly hasDownload: boolean;
  private readonly hasArg: boolean;
  private readonly hasInput: boolean;

  constructor(
    method: Method,
    url: string,
    outSchema: OutS,
    hasDownload: boolean,
    hasArg: boolean,
    hasInput: boolean
  ) {
    this.method = method;
    this.url = url;
    this.outSchema = outSchema;
    this.hasDownload = hasDownload;
    this.hasArg = hasArg;
    this.hasInput = hasInput;
  }

  private createApiCall() {
    return (arg: string | undefined, data: unknown) => {
      return callApi(
        this.method,
        this.url,
        this.outSchema,
        this.hasDownload,
        arg,
        data
      );
    };
  }

  call(): ApiCall<Arg, In, OutS["__outputType"]> {
    const call = this.createApiCall();
    if (this.hasArg && this.hasInput) {
      return ((arg: Arg, data: In) => {
        return call(arg, data);
      }) as ApiCall<Arg, In, OutS["__outputType"]>;
    } else if (this.hasArg) {
      return ((arg: Arg) => {
        return call(arg, undefined);
      }) as ApiCall<Arg, In, OutS["__outputType"]>;
    } else if (this.hasInput) {
      return ((data: In) => {
        return call(undefined, data);
      }) as ApiCall<Arg, In, OutS["__outputType"]>;
    } else {
      return (() => {
        return call(undefined, undefined);
      }) as ApiCall<Arg, In, OutS["__outputType"]>;
    }
  }

  use(): ApiHook<Arg, In, OutS["__outputType"]> {
    const use = createApiHook(this.createApiCall());
    if (this.hasArg && this.hasInput) {
      return ((arg: Arg, data: In) => {
        return use(arg, data);
      }) as ApiHook<Arg, In, OutS["__outputType"]>;
    } else if (this.hasArg) {
      return ((arg: Arg) => {
        return use(arg, undefined);
      }) as ApiHook<Arg, In, OutS["__outputType"]>;
    } else if (this.hasInput) {
      return ((data: In) => {
        return use(undefined, data);
      }) as ApiHook<Arg, In, OutS["__outputType"]>;
    } else {
      return (() => {
        return use(undefined, undefined);
      }) as ApiHook<Arg, In, OutS["__outputType"]>;
    }
  }

  arg<T extends string = string>() {
    return new Api<T, In, OutS>(
      this.method,
      this.url,
      this.outSchema,
      this.hasDownload,
      true,
      this.hasInput
    );
  }

  input<T>() {
    return new Api<Arg, T, OutS>(
      this.method,
      this.url,
      this.outSchema,
      this.hasDownload,
      this.hasArg,
      true
    );
  }

  inputSchema<InS extends AnySchema>(inSchema: InS) {
    return this.input<InS["__outputType"]>();
  }

  output<NewS extends AnySchema>(schema: NewS) {
    return new Api<Arg, In, NewS>(
      this.method,
      this.url,
      schema,
      this.hasDownload,
      this.hasArg,
      this.hasInput
    );
  }

  download() {
    return new Api<Arg, In, VoidSchema>(
      this.method,
      this.url,
      new VoidSchema(),
      true,
      this.hasArg,
      this.hasInput
    );
  }
}

export function api(method: Method, url: string) {
  return new Api<undefined, undefined, VoidSchema>(
    method,
    url,
    new VoidSchema(),
    false,
    false,
    false
  );
}
