import * as yup from "yup";
import { api } from "lib/api";

const output = yup
  .object({
    calibrationReleaseNotes: yup
      .array(
        yup
          .object({
            title_tr: yup.string().required(),
            url_tr: yup.string().required(),
            date: yup.date().required(),
          })
          .required()
      )
      .required(),
  })
  .required();

export const useReleaseNotes = api("GET", "v1/releaseNotes/")
  .output(output)
  .use();
