import { FormattedMessage, IntlProvider } from "react-intl";
import React, { ReactNode, useMemo } from "react";
import { defaultLocale, messages } from "config";
import { useSession } from "./session";

export function LanguageProvider({ children }: { children?: ReactNode }) {
  const { locale } = useSession();

  const currentMessages = useMemo(() => {
    return Object.fromEntries(
      Object.keys(messages[defaultLocale]).map((key) => {
        const message = (
          messages[locale] as Record<string, string | undefined>
        )[key];
        if (message === "" || !message) {
          return [
            key,
            (messages[defaultLocale] as Record<string, string>)[key],
          ];
        } else {
          return [key, message];
        }
      })
    );
  }, [locale]);

  return (
    <IntlProvider
      messages={currentMessages}
      locale={locale}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );
}

export const T = FormattedMessage;
