import * as yup from "yup";
import { api } from "lib/api";

export const loginInput = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const refreshInput = yup.object({
  token: yup.string().required(),
});

const output = yup.object({
  token: yup.string().required(),
  email: yup.string().email().required(),
  locale: yup.string().required(),
  name: yup.string().default(() => ""),
  handheld_iba_enabled: yup.boolean().default(() => true),
});

export type Session = yup.Asserts<typeof output>;

export const login = api("POST", "v1/login/")
  .inputSchema(loginInput)
  .output(output)
  .call();
export const loginRefresh = api("POST", "v1/login/refresh/")
  .inputSchema(refreshInput)
  .output(output)
  .call();

export const changeLanguage = api("PATCH", "v1/account/")
  .input<{ language: Locale }>()
  .call();
